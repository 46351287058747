import React, { useState } from "react";
import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
//import { Link } from 'react-router-dom';
import { Link } from "gatsby"
import MerppiLogo from "./MerppiLogo.js";
import "./navigbar.css";

export default function NavigBar() {

const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expanded={expanded} className="navigbar align-middle" expand="lg" fixed="top">
      <Navbar.Brand className="brandi" as={Link} to="/">
        <MerppiLogo width="150px" height="70px" />
      </Navbar.Brand>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="navlinks" id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/" className="m-auto">
            Etusivu
          </Nav.Link>
          <NavDropdown
            className="m-auto"
            title="Toiminnot"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item  onClick={() => setExpanded(false)} as={Link} to="/asiakkaat">
              Asiakkaat ja myynti
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} to="/tuotanto">
              Tuotanto ja toimitus
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} to="/varasto">Varasto</NavDropdown.Item>
            <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} to="/lisatoiminnot">
              Lisätoiminnot
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/contact">
            <Button variant="warning">Ota yhteyttä!</Button>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
