import React from "react";
import SEO from "../components/seo";
import NavigBar from "../components/NavigBar";

const Header = () => {
    return (
        <NavigBar />
    );
};

export default Header;