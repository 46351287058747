import React, { useRef, useState } from "react";
import { Form, Container, Button, Col, Alert } from "react-bootstrap";
import { load } from "recaptcha-v3";
const conf = require("../config");

export default function Contactform() {
  const [showsuccess, setsuccess] = useState(0);
  const formRef = useRef(null);
  const sendEmail = (e) => {
    load("6LcXr0kaAAAAAGd_-wbQ39dt6FSnEz-vAA4SiFhR").then((recaptcha) => {
      recaptcha.execute().then((token) => {
        let formData = new FormData(e.target);
        let formDataObj = Object.fromEntries(formData.entries());
        formDataObj["subject"] = conf.subject;
        formDataObj["token"] = token;
        formDataObj["type"] = 1;
        console.log("conf.requrl:", conf.requrl);
        let json2 = JSON.stringify(formDataObj);
        fetch(conf.requrl, {
          method: "POST",
          body: json2,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            formRef.current.reset();
            if (response.status === "success") {
              setsuccess(1);
              // alert("Message Sent.");
              //e.resetForm();
            } else if (response.status === "fail") {
              alert("Message failed to send.");
            }
          });
      });
    });
  };

  const onClick = (e) => {
    e.preventDefault();
    sendEmail(e);
  };
  return (
    <div>
      <Container className="page_container">
        <Col>
          <Form ref={formRef} onSubmit={onClick}>
            <Form.Row>
              <Col>
                <Form.Label>Etunimi</Form.Label>
                <Form.Control
                  type="name"
                  name="Etunimi"
                  placeholder="Etunimi"
                />
              </Col>
              <Col>
                <Form.Label>Sukunimi</Form.Label>
                <Form.Control
                  type="name"
                  name="Sukunimi"
                  placeholder="Sukunimi"
                />
              </Col>
            </Form.Row>
            <Form.Group controlId="formBasicMail">
              <Form.Label>Sähköpostiosoite</Form.Label>
              <Form.Control type="email" name="Email" placeholder="@mail" />
              <Form.Label>Puhelinnumero</Form.Label>
              <Form.Control
                type="number"
                name="Puhelin"
                placeholder="Puhelinnumero"
              />
              <Form.Label>Yrityksen nimi</Form.Label>
              <Form.Control
                type="business"
                name="Yrityksen nimi"
                placeholder="Yrityksen nimi"
              />
              <Form.Label>Viestisi</Form.Label>
              <Form.Control as="textarea" rows={5} name="Viesti" />
            </Form.Group>

            <Button
              style={{ marginTop: "20px" }}
              variant="warning"
              type="submit"
            >
              Lähetä
            </Button>
            <div style={{ marginBottom: "20px" }}></div>
            {showsuccess ? (
              <Alert variant="warning" type="info" headline="Oh Some Info">
                Viesti lähetetty!
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => setsuccess(false)}
                    variant="outline-warning"
                  >
                    Sulje ilmoitus
                  </Button>
                </div>
              </Alert>
            ) : null}
          </Form>
        </Col>
      </Container>
    </div>
  );
}
