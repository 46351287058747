import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.css";
import facebooklogo from "../img/facebook_logo.png";
//import { Link } from 'react-router-dom';
import { Link } from "gatsby"
import Contactform from "../components/Contactform";

const FooterPage = () => {
  return (
    <div className="footer">
      <Container fluid>
        <Row>
          <Col md="3">
           
              <img
                alt="facebook_logo"
               
                src={facebooklogo}
                style={{ width: "40px", marginBottom: "20px" }}
              />
              <p > @merppi</p>
          </Col>
          <Col md="4">
            <h5 className="title">Ota yhteyttä!</h5>
            <ul>
              <li className="list-unstyled">Sami Karsikas</li>
              <li className="list-unstyled">+358 40 960 3664</li>
              
            </ul>
 
          </Col>

          {/* <Col md="4" style={{lineHeight:"1.5"}}>
            <Contactform></Contactform>
          </Col> */}

          <Col md="4">
            <h5 className="title">Navigointi</h5>
            <ul>
              <li className="list-unstyled">
                <Link to="/etusivu">Etusivu</Link>{" "}
              </li>

              <li className="list-unstyled">
                <Link to="/asiakkaat">Asiakkaat ja myynti</Link>
              </li>
              <li className="list-unstyled">
                <Link to="/tuotanto">Tuotanto ja toimitus</Link>
              </li>
              <li className="list-unstyled">
                <Link to="/varasto">Varasto</Link>
              </li>
              <li className="list-unstyled">
                <Link to="/lisatoiminnot">Lisätoiminnot</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      <div className="footer-copyright">
        <Container fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a href="https://www.merppi.fi"> MERPPI </a>
        </Container>
      </div>
    </div>
  );
};

export default FooterPage;
