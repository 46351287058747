import React from "react";
import Logo2 from "../img/merppi_logo.svg";

const MerppiLogo = ({ width, height }) => {

  return (
    <div>
      <img src={Logo2} width={width} height={height} alt="merppi_logo"/>
    </div>
  );
};

export default MerppiLogo;
