// Main layout for whole site
import React from "react";
import "./App.css";
import "../../static/bootstrap/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
//import useSiteMetadata from "../hooks/useSiteMetadata";
//import { Route, Switch, Redirect } from "react-router-dom";
//import NavigBar from "./components/NavigBar";
//import Footer from "./components/Footer";
//import Homepage from "./pages";
//import AsiakkaatJaMyynti from "./Layouts/Toiminnot/AsiakkaatJaMyynti";
//import TuotantoJaToimitus from "./Layouts/Toiminnot/TuotantoJaToimitus";
//import Varasto from "./Layouts/Toiminnot/Varasto";
//import Lisatoiminnot from "./Layouts/Toiminnot/Lisatoiminnot";
//import Yhteydenotto from "./pages/contact"

const Layout = ({ children }) => {
  //const { title } = useSiteMetadata();
  return (
    <div>
      <Header siteTitle={"MERPPI"} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
